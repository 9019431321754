import { Body, Col, Grid, Row, Separator } from '@web/atomic';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import BreathWidget from '@lp-root/src/components/org.breath-widget/breath-widget.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { ProfileSelectionSection } from '@lp-root/src/modules/home/components/profile-selection-section.component';
import { appPaths } from '@lp-src/utils/path';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { PageProps } from '../utils/local-types';

const DeepBreathPage: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Respiração profunda',
          description: 'Ferramenta para fazer exercício de respiração profunda',
        }}
      />
      <Grid>
        <Row mt mb center="xs">
          <Col xs={12} sm={12} md={7} lg={7}>
            <Body>
              Selecione a forma que você quer praticar: 4/7/8 ou 3/5/6.
              <Link to={appPaths.deepBreathArticle.path}> Saiba mais sobre a técnica de respiração profunda</Link>
            </Body>
            <Separator />
            <BreathWidget />
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
      <ProfileSelectionSection />
    </IndexLayout>
  );
};

export const query = graphql`
  query DeepBreathPage {
    site {
      ...SiteUrl
    }
  }
`;

export default DeepBreathPage;
